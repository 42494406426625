export const footerData = {
  id: 1,
  logo: require("../images/logo_white.png"),
  info: "<h6>Realtime Decisions</h6>",
  phones: "<p>+91 93914 50930</p>",
  emails: "<p>info@meridiandatalabs.com</p>",
  redes: [
    {
      id: 1,
      icon: require("../images/facebook.svg"),
      name: "FACEBOOK"
    },
    {
      id: 2,
      icon: require("../images/instagram.svg"),
      name: "INSTAGRAM"
    },
    {
      id: 3,
      icon: require("../images/twitter.svg"),
      name: "TWITTER"
    }
  ],
  solutions: "<p>RAPID</p>",
  services: "<p>Massive IoT</p><p>Cloud Migration</p><p></p><p>Data Analytics</p>",
  resources: "<p>Lorem ipsum</p><p>Dolor sit</p><p>Consectutor</p>",
  about: "<p>About us</p><p>Our partners</p><p>Our philosophy</p><p> Our team</p>"
}
