import { Container, Row, Col, Image } from "react-bootstrap"
import React from "react"
import "./services.scss"

/* data */
import { serviceData } from "../../data/services"

export default function services() {
  const servicesHTML = serviceData.map((service, j) => {
    const listAHTML = service.list_a.map((x, i) => <li key={`a-${j}-${i}`}>{x}</li>)
    const listBHTML = service.list_b.map((x, i) => <li key={`b-${j}-${i}`}>{x}</li>)

    return (
      <div className="content-service" key={service.title}>
        <Row>
          <Col>
            <Image src={service.image} className="mb-4" />
            <h5>{service.title}</h5>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <p>{service.content}</p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <ul>{listAHTML}</ul>
          </Col>
          <Col>
            <ul>{listBHTML}</ul>
          </Col>
        </Row>
      </div>
    )
  })

  return (
    <div className="service" id="Services">
      <Container>
        <Row>
          <Col className="text-center">
            <h2>SERVICES</h2>
          </Col>
        </Row>
        <Row>
          <Col>{servicesHTML}</Col>
        </Row>
      </Container>
    </div>
  )
}
