import { Container, Row, Col, Button, Form } from "react-bootstrap"
import validate from "./subscriptionFormRules"
import handleForm from "../handlers/handleForm"
import React from "react"
import "./resources.scss"

/* data */
import { subscriptionData } from "../../data/subscription"

export default function subscription() {

  const sendEmail = () => {
    console.log("form subscription success!");
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = handleForm(sendEmail, validate);  

  return (
    <div className="subscription" data-aos="fade-up">
      <Container>
        <Row>
          <Col className="text-left">
            <h2>{subscriptionData.title}</h2>
            <p>{subscriptionData.content}</p>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit} noValidate>
          <Form.Control type="hidden" name="form" value="form_subscription"></Form.Control> 
          <Row>
            <Col xs={12} md={10}>
              <Form.Control type="text" 
                              name="email" 
                              value={values.email || ''} 
                              onChange={handleChange}
                              required></Form.Control>
                {errors.email && (
                  <p className="help is-danger">{errors.email}</p>
                )}
            </Col>
            <Col xs={12} md={2}>
              <Button className="w-100" type="submit">SEND</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  )
}
