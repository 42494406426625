import { Navbar, Nav, Image } from "react-bootstrap"
import handleScroll from "../handlers/handleScroll"
import ScrollchorItem from "./scrollchorItem"
import React from "react"

import "./menu.scss"

/* data */
import { resourcesData } from "../../data/resources"
import { menuData } from "../../data/menu"

export default function menu() {
  let navbarClasses = ["contentMenu"]
  let headerClasses = ["menu"]

  const scrolled = handleScroll()

  if (scrolled) {
    navbarClasses.push("scrolled")
    headerClasses.push("scrolled")
  }

  const navItemsPrint = menuData.navItems.map(item => {
    return item.name === 'Resources' && resourcesData.length === 0 ?    
      <div className="d-none" key={item.id}> </div>  
      :
      <Nav.Item key={item.id}>
        <Nav.Link href={item.link}>
          <ScrollchorItem to={item.link}>{item.name}</ScrollchorItem>
        </Nav.Link>
      </Nav.Item>      
  })

  return (
    <header className={headerClasses.join(" ")}>
      <Navbar collapseOnSelect expand="lg" className={navbarClasses.join(" ")}>
        <Image src={menuData.logo} className="logo d-block d-lg-none" />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <ScrollchorItem to="#started" animate={{ offset: -300, duration: 400 }}>
              <Image src={menuData.logo} className="logo d-none d-lg-block" />
            </ScrollchorItem>
          </Nav>
          <Nav className="justify-content-end" activeKey="/home">
            {navItemsPrint}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}
