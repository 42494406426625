import { Container, Row, Col, Image } from "react-bootstrap"
import Scrollchor from "react-scrollchor"
import React from "react"
import "./footer.scss"

/* data */
import { resourcesData } from "../../data/resources"
import { footerData } from "../../data/footer"

export default function footer() {

  return (
    <div className="footer">
      <Container className="content-footer">
        <Row>
          <Col xs={12} md={12} lg={4} className="content-footer-logo">
            <Image src={footerData.logo} />
            <div className="mt-5" dangerouslySetInnerHTML={{
                  __html: `<p>${footerData.info}</p>`
                }}
            ></div>
          </Col>
          <Col xs={12} md={12} lg={8}>
            <Row className="row-footer-info info-contact">
              <Col xs={12} md={4}>
                <h5 className="d-block mb-4">Phone</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p>${footerData.phones}</p>`
                  }}
                ></div>
              </Col>

              <Col xs={12} md={4}>
                <h5 className="d-block mb-4">Email</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p>${footerData.emails}</p>`
                  }}
                ></div>
              </Col>

              <Col xs={12} md={4} lg={4}>
                <Scrollchor to="#Contact" className=" d-block">
                  <h5 className="d-block mb-4">Contact Us</h5>
                </Scrollchor>
              </Col>
            </Row>
            <div className="border-top-custom"></div>
            <Row className="row-footer-info">
              <Col xs={6} md={4} lg={3}>
                <Scrollchor to="#Rapid">
                  <h5 className="d-block mb-4">Solutions</h5>
                </Scrollchor>
                <div className="mt-5" dangerouslySetInnerHTML={{
                      __html: `<p>${footerData.solutions}</p>`
                    }}
                ></div>                  
              </Col>
              <Col xs={6} md={4} lg={3} className="mb-5">
                <Scrollchor to="#Services">
                  <h5 className="d-block mb-4">Services</h5>
                </Scrollchor>
                <div className="mt-5" dangerouslySetInnerHTML={{
                      __html: `<p>${footerData.services}</p>`
                    }}
                ></div>  
              </Col>
              {resourcesData.length > 0 &&
                <Col xs={6} md={4} lg={3}>
                  <Scrollchor to="#Resources">
                    <h5 className="d-block mb-4">Resources</h5>
                  </Scrollchor>
                  <div className="mt-5" dangerouslySetInnerHTML={{
                        __html: `<p>${footerData.resources}</p>`
                      }}
                  ></div>  
                </Col>
              }
              <Col xs={6} md={4} lg={3}>
                <Scrollchor to="#About">
                  <h5 className="d-block mb-4">About Us</h5>
                </Scrollchor>
                <div className="mt-5" dangerouslySetInnerHTML={{
                      __html: `<p>${footerData.about}</p>`
                    }}
                ></div> 
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
