import { Container, Row, Col, Image } from "react-bootstrap"
import React from "react"
import "./about.scss"

/* data */
import { aboutData } from "../../data/about"

export default function about() {
  return (
    <div className="about" id="About">
      <Container>
        <Row>
          <Col xs={12} md={5}>
            <Image src={aboutData.image} />
          </Col>
          <Col xs={12} md={7} className="content-info-about">
            <h2 className="mb-4">{aboutData.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: aboutData.content }} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
