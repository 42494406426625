import { Container, Row, Col, Image } from "react-bootstrap"
import React from "react"
import "./partner.scss"

/* data */
import { partnerData } from "../../data/partner"

export default function partner() {
  const partnerDataPrint = partnerData.map(partner => (
    <Col xs={12} md={3} key={partner.id} className="text-center">
      <Image src={partner.image} />
    </Col>
  ))
  return (
    <div className="partner">
      <Container>
        <div className="border-top-custom w-80"></div>
        <Row className="text-center mb-5" >
          <Col>
            <h2>Technology Partners</h2>
          </Col>
        </Row>
        <Row>{partnerDataPrint}</Row>
      </Container>
    </div>
  )
}
