import SEO from "../components/seo"
import Footer from "../components/footer"
import Menu from "../components/menu"
import React from "react"
import "./style.scss"

export default function Layout(props) {
  const { children } = props

  return (
    <>
      <SEO />
      <Menu />
      {children}
      <Footer />
    </>
  )
}
