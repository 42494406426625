import React from "react"
import Layout from "../layouts/layout"
import Started from "../components/started"
import Rapid from "../components/rapid"
import Services from "../components/services"
import Resources from "../components/resources"
import About from "../components/about"
import Team from "../components/team"
import Partner from "../components/partners"
import Contact from "../components/contact"

export default function Index() {
  return (
    <Layout>
      <Started />
      <Rapid />
      <Services />
      <Resources />
      <About />
      <Team />
      <Partner />
      <Contact />
    </Layout>
  )
}
