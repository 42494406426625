export const serviceData = [
  {
    id: 1,
    title: "Massive IoT",
    image: require("../images/iot_icon.svg"),
    content:
      "From fleet management to hospital inventory device management and tracking, our large scale IoT platform enables you to manage and analyze critical IoT data in realtime. Our platform ingests, manipulates, and analyses the data to provide much needed information about your IoT infrastructure. The platform also supports a wide range of devices which include low powered, long battery life, and offline devices. The data collected is stored securely and managed by our platform. We also support B2C and B2B applications of our platform.",
    list_a: ["Scalable connectivity", "Reliability for difficult conditions and remote environments"],
    list_b: ["Highend security", "AI powered analytics"]
  },
  {
    id: 2,
    title: "Cloud Migration Services",
    image: require("../images/migration_icon.svg"),
    content:
      "Our cloud migration services will empower your business with the scalability that the cloud provides. Our team will perform discovery, analyze your infrastructure, create a migration strategy that best suits your business, and then execute the migration while guranteeing a smooth transition to the cloud.",
    list_a: ["On-premise infrastructure & applications analysis", "Cloud migration strategy"],
    list_b: ["Reduced impact risks", "Multi-cloud support"]
  },
  {
    id: 3,
    title: "Data Analytics",
    image: require("../images/analytics_icon.svg"),
    content:
      "Data is powering the economy today, businesses with smart and automated data analytics will be able to unleash the power of data and achieve higher levels of success. MDL will enable you to make better decisions by making comprehensive visualizations of your data models and enhancing your data models to better fit your needs. Data science has had tremendous impact in this industry by enhancing the ability to extract knowledge from data sets. Our team will use their skills in computer science, mathematics, statistics, and graphic design to create intuitive solutions for your business maintaining the data at its core. Our goal is to enable you to achieve your business goals.",
    list_a: ["Improved decision making", "Data visualization", "Data science acceleration"],
    list_b: ["Analytics at scale", "Cloud analytics modernization"]
  }
]
