import { Container, Row, Col, Form, Button, Image } from "react-bootstrap"
import handleForm from "../handlers/handleForm"
import validate from "./contactFormRules"
import React from "react"
import "./contact.scss"

import { contactData } from "../../data/contact"

export default function contact() {
  const sendEmail = () => {
    console.log("form contact success!")
  }

  const { values, errors, handleChange, handleSubmit } = handleForm(
    sendEmail,
    validate
  )

  const contactInfoPrint = contactData.map(contact => (
    <Col xs={12} md={12} lg={4} className="p-5" key={contact.id}>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <Image src={contact.icon} className="icon" />
        </Col>
        <Col xs={12} md={12} lg={8}>
          {contact.address}
        </Col>
      </Row>
    </Col>
  ))

  return (
    <div className="contact" id="Contact">
      <Container>
        <div className="content_contact">
          <h2 className="mb-5">CONTACT US</h2>
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Control
              type="hidden"
              name="form"
              value="form_contact"
            ></Form.Control>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name || ""}
                    onChange={handleChange}
                    required
                  ></Form.Control>
                  {errors.name && (
                    <p className="help is-danger">{errors.email}</p>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={values.phone || ""}
                    onChange={handleChange}
                    required
                  ></Form.Control>
                  {errors.phone && (
                    <p className="help is-danger">{errors.phone}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} md={10}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={values.email || ""}
                    onChange={handleChange}
                    required
                  ></Form.Control>
                  {errors.email && (
                    <p className="help is-danger">{errors.email}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="message"
                    value={values.message || ""}
                    onChange={handleChange}
                    required
                  ></Form.Control>
                  {errors.message && (
                    <p className="help is-danger">{errors.message}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-4 mb-5">
                <Button type="submit">SEND</Button>
              </Col>
            </Row>
          </Form>

          <Row className="mt-5">
            <Col xs={12}>
              <h5>
                <b>Locations</b>
              </h5>
            </Col>
            {contactInfoPrint}
          </Row>
        </div>
      </Container>
    </div>
  )
}
