export const contactData = [
  {
    id: 1,
    icon: require("../images/pin_icon.svg"),
    address: "Toronto",
  },
  {
    id: 2,
    icon: require("../images/pin_icon.svg"),
    address: "Dubai",
  },
  {
    id: 3,
    icon: require("../images/pin_icon.svg"),
    address: "Hyderabad",
  },
]
