import { Container, Row, Col, Image, Button } from "react-bootstrap"
import Scrollchor from "react-scrollchor"
import React from "react"
import "./started.scss"

/* images */
import bannerStarted from "../../images/banner-started.png"
import scrollDown from "../../images/arrow_down.svg"

/* data */
import { startedData } from "../../data/started"

export default function started() {
  return (
    <Container fluid className="started" id="started">
      <Row className="mb-5">
        <Col xs={12} md={6} className="order-md-1 order-2 d-table">
          <div className="d-table-cell align-middle">
            <div className="content-banner-started">
              <Image src={bannerStarted} className="banner-started" />
              <Scrollchor to="#Contact" className="success d-block d-md-none">
                <Button variant="">
                    GET STARTED
                </Button>
              </Scrollchor>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="order-md-1 order-1 content-info-started d-table">
          <div className="d-table-cell align-middle">
            <h1
              dangerouslySetInnerHTML={{
                __html: startedData.content
              }}
            ></h1>            
            <Scrollchor to="#Contact" className="success d-none d-md-inline">
              <Button variant="">
                  GET STARTED
              </Button>
            </Scrollchor>            
          </div>
        </Col>
      </Row>

      <div className="content-scroll-down">
        <Scrollchor to="#Rapid">
          <Image src={scrollDown} />
          Scroll down
        </Scrollchor>
      </div>
    </Container>
  )
}
