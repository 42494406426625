import { Container, Row, Col, Image, Button } from "react-bootstrap"
import Scrollchor from "react-scrollchor"
import React from "react"
import "./rapid.scss"

/* images */
import bannerRapid from "../../images/rapid.png"

/* data */
import { rapidData } from "../../data/rapid"

export default function rapid() {
  return (
    <div className="rapid" id="Rapid">
      <Container>
        <Row>
          <Col xs={12} md={5} className="content-info-rapid">
            <h2>{rapidData.title}</h2>
            <Image
              src={bannerRapid}
              className="banner-rapid w-90 d-block d-md-none"
            />
            <p className="mb-4">{rapidData.content}</p>
            <Scrollchor to="#Contact" className="success">
              <Button variant="">
                  GET STARTED
              </Button>
            </Scrollchor>
          </Col>
          <Col xs={12} md={7}>
            <Image
              src={bannerRapid}
              className="banner-rapid w-90 d-none d-md-block"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
