import { Container, Row, Col, Card } from "react-bootstrap"
import Carousel from "react-elastic-carousel"
import Subscription from "./subscription"
import { Link } from "gatsby"
import "./resources.scss"
import React from "react"

/* data */
import { resourcesData } from "../../data/resources"

export default function resources() {
  const itemsCard = resourcesData.map(resources => (
    <div key={resources.id}>
      <Card className="mb-2">
        <Card.Img variant="top" src={resources.image} />

        <Card.Body className="p-4">
          <Card.Title>{resources.title}</Card.Title>
          <Card.Text>{resources.content}</Card.Text>
          <Link to={resources.link}>KNOW MORE</Link>
        </Card.Body>
      </Card>
    </div>
  ))

  return (
    <>
      {resourcesData.length > 0 &&
        <div>
          <div className="resources" id="Resources">
            <Container>
              <h2>RESOURCES</h2>
              <Row>
                <Col xs={12} className="d-none d-lg-block">
                  <Carousel itemsToShow={3} pagination={false} className="mt-5 ">
                    {itemsCard}
                  </Carousel>
                </Col>

                <Col xs={12} className="d-none d-md-block d-lg-none">
                  <Carousel itemsToShow={2} pagination={false} className="mt-5 ">
                    {itemsCard}
                  </Carousel>
                </Col>

                <Col xs={12} className="d-sm-block d-md-none">
                  <Carousel
                    itemsToShow={1}
                    pagination={false}
                    showArrows={false}
                    className="mt-5 "
                  >
                    {itemsCard}
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </div>
          <Subscription/>
        </div>
     }
    </>
  )
}
