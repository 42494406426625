export const partnerData = [
  {
    id: 1,
    image: require("../images/partner_sigfox-min.png"),
  },
  {
    id: 2,
    image: require("../images/partner_aws-min.png"),
  },
  {
    id: 3,
    image: require("../images/partner_google-min.png"),
  },
  {
    id: 4,
    image: require("../images/partner_microsoft-min.png"),
  },
]
