export const menuData = {
  logo: require("../images/logo_black.png"),
  navItems: [
    {
      id: 1,
      name: "Solutions",
      link: "#Rapid",
    },
    {
      id: 2,
      name: "Services",
      link: "#Services",
    },
    {
      id: 3,
      name: "Resources",
      link: "#Resources",
    },
    {
      id: 4,
      name: "About Us",
      link: "#About",
    },
    {
      id: 5,
      name: "Contact",
      link: "#Contact",
    },
    { id: 6, name: "Access RAPID Platform", link: "#Contact" },
  ],
}
