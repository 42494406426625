import { Container, Row, Col, Image } from "react-bootstrap"
import React from "react"

import "./team.scss"

/* data */
import { teamData } from "../../data/team"

export default function team() {
  return (
    <div className="team">
      <Container>
        <Row>
          <Col md={4} className="d-none d-md-block" data-aos="fade-right">
            <Image src={teamData[0].image} className="banner-team w-100" />
          </Col>
          <Col xs={12} md={8} data-aos="fade-left">
            <div className="content-info-about">
              <h4>
                <Image src={teamData[0].icon} className="icon icon-left" />
                {teamData[0].title}
              </h4>
              <Image src={teamData[0].image} className="banner-team d-block d-md-none w-100" />
              <div dangerouslySetInnerHTML={{ __html: teamData[0].content }} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} className="text-right">
            <div className="content-info-about">
              <h4>
                {teamData[1].title}
                <Image src={teamData[1].icon} className="icon icon-right" />
              </h4>
              <Image src={teamData[1].image} className="d-block d-md-none w-100" />
              <div dangerouslySetInnerHTML={{ __html: teamData[1].content }} />
            </div>
          </Col>
          <Col xs={12} md={4} className="d-none d-md-block">
            <Image src={teamData[1].image} className="banner-team w-100" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
