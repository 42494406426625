import { useState, useEffect } from "react"

const HandleScroll=() => {    

  const [scrolled, setScrolled]= useState( false );  
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    setScrolled(window.scrollY > 10);     
  }
  
  return scrolled;
}

export default HandleScroll;