export const teamData = [
  {
    id: 1,
    title: "Our Philosophy",
    icon: require("../images/philosophy_icon.svg"),
    image: require("../images/philosophy_bg.png"),
    content:
      "<p>We believe, decisions based on real-time data is essential for current fast paced global business environment.  IOT, Hyperconnectivity and big data management are key assets to digitally transform a business and provide efficient data solutions.  At MDL, we see our clients as partners working along our side to achieve a common goal, that is GROWTH.  Our expertise and scientific knowledge sets the base for each one of our projects.  We combine machine learning and data science tools to provide you with deeper insights, that lead to a more accurate decision-making process.</p>"
  },
  {
    id: 2,
    title: "Our Team",
    icon: require("../images/team_icon.svg"),
    image: require("../images/team_bg.png"),
    content: `<p>Our expert team is well qualified, picked from the best institutes in the world, take on projects of varying complexity and provide cost efficient, cutting-edge and globally standard solutions to our clients.<p>
<p>As a multicultural team, we thrive to excel by applying Machine Learning, Artificial Intelligence, and Data Science
technologies, while adapting our processes to customer needs.</p>
<p>We are passionate about creating custom solutions that help our clients optimize their processes and digitally transform
their business. Whether the challenge is a cloud migration project or a data analytics service, our professionals are
prepared to deliver the results you need.</p>`
  }
]
